<template>
    <div>
        <vx-card class="mt-10 mb-10">
            <div v-if="selected.length > 0" class="float-right">
                <vs-button color="transparent" text-color="gray" @click="activeDeleteDialog()">
                    <feather-icon icon="TrashIcon"></feather-icon>
                </vs-button>
            </div>

            <vs-button v-else @click="findInventories()" color="transparent" text-color="gray" class="float-right">
                <feather-icon icon="RefreshCwIcon" :class="{ 'animate-spin': loading }"></feather-icon>
            </vs-button>

            <h2 class="text-info mb-5">{{ this.$t('Listado de Preordenes') }}</h2>

            <div class="flex mt-10 justify-right">
                <!-- eslint-disable -->
                <vs-select :label="this.$t('Items por página')" v-model="pagination.page_size" class="w-36">
                    <vs-select-item :key="index" :value="item" :text="item" v-for="item,index in maxItemsVals" />
                </vs-select>

                <!-- <div class="w-1/6 border-2 h-12 border-solid rounded-full" @click="$refs['products-search'].focus()">
                    <div class="flex h-full">
                        <feather-icon :icon="'SearchIcon'" class="mt-2 mb-2 ml-2" />
                        <input
                            class="h-full w-full border border-none mr-5"
                            ref="products-search"
                            v-model="pagination.name"
                        />
                    </div>
                </div> -->
            </div>

            <vs-table v-model="selected" :data="inventories" :noDataText=" this.$t( 'Datos no disponibles' ) ">

                <template slot="thead">
                    <vs-th>{{ this.$t('Nombre de Grupo') }}</vs-th>
                    <vs-th>{{ this.$t('ID Usuario') }}</vs-th>
                    <vs-th>{{ this.$t('Nombre Usuario') }}</vs-th>
                    <vs-th></vs-th>
                    <vs-th></vs-th>
                </template>

                <template slot-scope="{data}">
                    <vs-tr :data="tr" v-bind:key="indextr" v-for="(tr, indextr) in data">
                        
                        <!-- precio -->
                        <vs-td :data="data[indextr].group_name">
                            {{ data[indextr].group_name  }}
                        </vs-td>

                        <!-- cantidad -->
                        <vs-td :data="data[indextr].identification">
                            {{ data[indextr].identification }}
                        </vs-td>

                        
                        <!-- promo -->
                        <vs-td :data="data[indextr].name_user">
                            {{ data[indextr].name_user }}
                        </vs-td>

                        <!-- eliminar -->
                        <vs-td :data="data[indextr].id">
                            <vs-td :data="data[indextr].status">
                                <vs-switch v-model="data[indextr].groups_status" @click.stop="deleteProducts( data[indextr].group_id , data[indextr].user_id )"/>
                            </vs-td>
                        </vs-td>
                        <vs-td :data="data[indextr].id">
                            <vs-button @click="showProducts( data[indextr].id )" data-toggle="tooltip" data-placement="top" size="small" color="transparent" >
                                <img :src="require(`@/assets/icons/Eye2.svg`)" width="23px" />
                            </vs-button>
                            
                            <!-- <vs-button data-toggle="tooltip" data-placement="top" title="Eliminar" color="transparent" @click="activeDeleteDialog()">
                                <img :src="require(`@/assets/icons/DeleteGrayIcon.svg`)" width="14px" />
                            </vs-button> -->
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>

            <vs-pagination :total="pagination.total" v-model="pagination.current" class="mt-5"></vs-pagination>
        </vx-card>
        <SidebarShowProducts :sidebar="sidebar" :data="productsToShow" @closeSidebar="closeSidebar()"/>
    </div>
</template>

<script lang="ts">
import Vue from 'vue'
import FeatherIcon from '../../../components/FeatherIcon.vue';
import SidebarShowProducts from './SidebarShowProducts.vue';

export default Vue.extend({
  components: { FeatherIcon, SidebarShowProducts },
    data() {
        return {
            sidebar          : false,
            productsToShow   : [],
            selected     : [],
            activePrompt : false,
            loading      : false,
            pagination   : { total: 1, current: 1, page_size: 25, name: '' },
            timeout      : null,
            maxItemsVals : [5,10,15,20,25,30,35,40,45,50],
        }
    },

    created() {
        this.$store.dispatch( 'stores/getAllStores' );
        // this.$store.dispatch( 'inventories/getAllInventories' );
        this.findInventories();
    },

    watch: {
        'pagination.current'() { this.findInventories(); },
        'pagination.page_size'() { this.findInventories(); },
        'pagination.name'() {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.findInventories();
            }, 1000);
        },
    },

    methods: {
        
        activeDeleteDialog() {
            this.activePrompt = true;
        },

        deleteProducts(gid, user_id) {
            this.loading = true;
            const d = {id: gid, uid: user_id}
            this.$store.dispatch( 'preorders/deletePreOrder', d );
            
            setTimeout(() => {
                this.closeDeleteDialog();
                this.loading = false;
            }, 1000);
        },

        closeDeleteDialog() {
            this.selected = [];
            this.activePrompt = false;
        },

        async refreshProductsList() {
            this.refreshProducts = true;
            const payload = {
                page      : this.pagination.current,
                page_size : this.pagination.page_size,
                name      : this.pagination.name,
            };
            await this.$store.dispatch( 'preorders/getPreOrdersFind', payload);
            this.refreshProducts = false;
        },

        async findInventories() {
            this.loading = true
            const payload = {
                page      : this.pagination.current,
                page_size : this.pagination.page_size,
                name      : this.pagination.name,
            };

            const results = await this.$store.dispatch('preorders/getPreOrdersFind', payload);
            
            if( !results ) this.loading = false;
            else {
                this.loading = false;
                this.pagination.total = results.data.total_pages;
            }
        },

        showProducts( id ) {
            
            const order         = this.inventories.find( order => order.id === id );
            this.productsToShow = order.products;
            this.sidebar        = true;
        },

        closeSidebar() {
            this.productsToShow = [];
            this.sidebar        = false;
        },
    },

    computed: {
        inventories() {
            
            let preorders = this.$store.state.preorders.preorders.result;
            //console.clear();       
            if (preorders != undefined){
                let inventoriesMapped = preorders.map(prod => {
                    let ob = Object.assign({});
                    ob.edit = false;
                    ob.collapsed = true;
                    ob.id = prod.group_id + '_' + prod.identification;
                    ob.group_id = prod.group_id
                    ob.group_name = prod.group_name;
                    ob.groups_status = prod.groups_status;
                    ob.identification = prod.identification;
                    ob.name_user = prod.name_user;
                    ob.products = prod.products;
                    ob.shop_id = prod.shop_id;
                    ob.user_id = prod.user_id
                    return ob;
                });     
                return inventoriesMapped; 
            } else{
                return [];
            }
        }
    },
});
</script>

<style lang="scss">
.vs-dialog-header {
    display: none !important;
}
</style>