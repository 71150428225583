<template>
        <vs-sidebar click-not-close position-right parent="#app" class="hours-sidebar" spacer v-model="localActiveSidebar">
            <div class="mt-6 flex items-center justify-between px-6" slot="header">
                <h4 class="text-white"> {{ this.$t('Listado De Productos') }} </h4>
                <feather-icon icon="XIcon" @click.stop="localActiveSidebar = false" class="cursor-pointer"></feather-icon>
            </div>

            <VuePerfectScrollbar class="scroll-area--data-list-add-new">
                <div class="p-6">

                    <div v-for="product in products" v-bind:key="product.id" class="vx-row mt-2">
                        <!-- imagen del prodcuto -->
                        <div class="vx-col w-1/6 p-0">
                            <img :src="product.image" width="75"/>
                        </div>

                        <!-- nombre del producto -->
                        <div class="vx-col w-2/3">
                            <p class="text-md"><!--  <strong>SKU:</strong> --> {{ product.sku | sku }}</p>
                            <p class="text-md"> <!-- <strong>NOMBRE:</strong> -->{{ product.title }} </p>
                        </div>
                        <!-- cantidad de producto -->
                        <div class="vx-col w-1">
                            <p class="text-md"> x{{ product.quantity }} </p>
                        </div>

                        <!-- precio -->
                        <!-- <div class="vx-col w-1/5">
                            <p class="text-sm"> {{ product.price | coin }} </p>
                        </div> -->

                        <div class="vx-col w-full">
                            <hr/>
                        </div>
                    </div>                    

                </div>

            </VuePerfectScrollbar>
            
            <div class=" items-center p-6" slot="footer">
                <button class="vs-component vs-button float-right bg-info vs-button-filled mb-2" @click="localActiveSidebar = false">
                    {{ this.$t('Cerrar') }}
                </button>
            </div>
        </vs-sidebar>
</template>

<script lang="ts">
import Vue from 'vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default Vue.extend({
    props: {
        sidebar: {
            required: true,
            type: Boolean,
        },

        data: {
            required: true,
        },

    },

    computed: {
        localActiveSidebar: {
            get: function() {
                return this.sidebar;
            },
            set: function( ) {                
                this.$emit( 'closeSidebar', true );
            }
        },

        products: {
            get: function() {
                return this.data;
            }
        }
    },

    components: {
        VuePerfectScrollbar,
    }
})
</script>

<style lang="scss" scoped>
.hours-sidebar {
    ::v-deep .vs-sidebar--background {
        z-index: 52010;
    }
    
    ::v-deep .vs-sidebar {
        position: fixed;
        z-index: 52010;
        width: 350px;
        max-width: 90vw;
        .vs-sidebar--header {
            background: #841910;
            color: #FFF;
        }
    }
}

.scroll-area--data-list-add-new {
    height: calc(100% - 4.3rem);
}
</style>